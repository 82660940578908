import { Component, DestroyRef, EventEmitter, Inject, Injector, Input, Output } from '@angular/core';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { BaseField } from '../base-field';
import { ValidationErrorsComponent } from 'shared/components/forms/validation-errors/validation-errors.component';

@Component({
  selector: 'input-field',
  templateUrl: './input-field.component.html',
  standalone: true,
  imports: [ReactiveFormsModule, NgIf, ValidationErrorsComponent, FaIconComponent, NgTemplateOutlet],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: InputFieldComponent, multi: true }],
})
export class InputFieldComponent extends BaseField {

  @Input() type: 'text' | 'number' | 'password' = 'text';
  @Input() description!: string;
  @Input() iconRight?: IconName;
  @Input() iconLeft?: IconName;

  @Output() clickRight = new EventEmitter<any>();
  @Output() clickLeft = new EventEmitter<any>();

  constructor(
    injector: Injector,
    @Inject(DestroyRef) protected destroyRef: DestroyRef
  ) {
    super(injector, destroyRef);
  }

  clickIconRight() {
    this.clickRight.emit(this.control.value);
  }

  clickIconLeft() {
    this.clickLeft.emit(this.control.value);
  }
}
