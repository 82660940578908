<div class="form-group">
  <label *ngIf="label" [for]="'field-component-' + formControlName + (controlContainer?.name ? '_' + controlContainer.name : '')">{{ label }}<span *ngIf="required"> *</span></label>
  <ng-container *ngIf="!iconRight && !iconLeft; else iconTemplate">
    <ng-template [ngTemplateOutlet]="input"></ng-template>
  </ng-container>
  <validation-errors *ngIf="control?.invalid && (control?.dirty || control?.touched)" [errors]="control?.errors" [label]="label" class="invalid-feedback"></validation-errors>
  <small *ngIf="description" class="form-text text-muted">{{ description }}</small>
</div>

<ng-template #iconTemplate>
  <div class="input-group input-group-sm">
    <div *ngIf="iconLeft" class="input-group-text pointer" (click)="clickIconLeft()">
      <fa-icon [icon]="['fas', iconLeft]" [fixedWidth]="true"></fa-icon>
    </div>
    <ng-template [ngTemplateOutlet]="input"></ng-template>
    <div *ngIf="iconRight" class="input-group-text pointer" (click)="clickIconRight()">
      <fa-icon [icon]="['fas', iconRight]" [fixedWidth]="true"></fa-icon>
    </div>
  </div>
</ng-template>

<ng-template #input>
  <input
    [type]="type"
    [id]="'field-component-' + formControlName + (controlContainer?.name ? '_' + controlContainer.name : '')"
    [formControl]="control"
    [required]="required"
    [readonly]="readonly"
    class="form-control form-control-sm"
  >
</ng-template>
